<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">


              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('supplier_transactions_report.supplier') }}</label>
                <multiselect v-model="supplier"
                             :placeholder="$t('supplier_transactions_report.supplier')"
                             label="name"
                             track-by="id"
                             :options="suppliers"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getSuppliers($event)">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('supplier_transactions_report.currency') }}</label>
                <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                  <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('supplier_transactions_report.from_date') }}</label>
                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('supplier_transactions_report.to_date') }}</label>
                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
              </div>


              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!-- End Filter -->
    <div class="card card-custom">
      <div class="card-body">
        <div class="mt-3">
          <div class="row mb-10">
            <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
              <h4 class="text-center">{{ $t('supplier_transactions_report.supplier_transactions_report') }}</h4>
              <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }} {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
            </div>

          </div>
          <div class="row mb-10">
            <div class="col-12" v-if="data_report_details">
              <div class="table-responsive">
                <div class="row justify-content-end p-4">
                  <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                    <button class="dropdown-item" @click="convertTableToExcelReport('summaryTableDetails')">
                      <i class="la la-file-excel"></i>{{ $t('excel') }}
                    </button>
                    <button class="dropdown-item" @click="printData('summaryTableDetails')">
                      <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                    </button>
                    <button class="dropdown-item" @click="printPDFData('summaryTableDetails', 'pdf')">
                      <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                    </button>
                    <button class="dropdown-item" @click="getDataForExport('sectionForExport', 'excel')">
                      <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                    </button>
                  </b-dropdown>
                </div>
                <table class="table table-bordered" id="summaryTableDetails">
                  <thead>
                  <tr>
                    <th>{{ $t('purchases_payments_periodic_reports.id') }}</th>
                    <th>{{ $t('supplier_transactions_report.doc_created_by') }}</th>
                    <th>{{ $t('supplier_transactions_report.doc_created_at') }}</th>
                    <th>{{ $t('supplier_transactions_report.currency') }}</th>
                    <th>{{ $t('supplier_transactions_report.amount') }}</th>
                    <th>{{ $t('supplier_transactions_report.exchange_rate') }}</th>
                    <th>{{ $t('supplier_transactions_report.doc_type') }}</th>
                    <th>{{ $t('supplier_transactions_report.doc_title') }}</th>
                    <th>{{ $t('supplier_transactions_report.balance_before') }}</th>
                    <th>{{ $t('supplier_transactions_report.balance_after') }}</th>
                    <th>{{ $t('supplier_transactions_report.wanted_amount') }}</th>
                    <th>{{ $t('supplier_transactions_report.paid_amount') }}</th>
                  </tr>
                  </thead>
                  <tbody>

                  <template v-if="data_report_details">
                    <tr v-for="(row, index) in data_report_details" :key="index">
                      <td>{{ row.id }}</td>
                      <td>{{ row.doc_created_by ? row.doc_created_by : '' }}</td>
                      <td>{{ row.doc_created_at ? row.doc_created_at : '' }}</td>
                      <td>{{ row.currency_name }}</td>
                      <td>{{ row.amount }}</td>
                      <td>{{ row.exchange_rate }}</td>
                      <td>{{ row.doc_type }}</td>
                      <td>{{ row.doc_title }}</td>
                      <td>{{ row.balance_before }}</td>
                      <td>{{ row.balance_after }}</td>
                      <td>{{ row.wanted_amount }}</td>
                      <td>{{ row.paid_amount }}</td>
                    </tr>
                  </template>


                  </tbody>
                </table>
              </div>
              <div class="text-center" v-if="current_page < last_page">
                <button class="btn btn-warning" @click="loadMore">
                  <p class="mb-0">
                    <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                    {{ $t('load_more') }}
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!--end::supplier-->
    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered" id="sectionForExport">
          <thead>
          <tr>
            <th>{{ $t('purchases_payments_periodic_reports.id') }}</th>
            <th>{{ $t('supplier_transactions_report.doc_created_by') }}</th>
            <th>{{ $t('supplier_transactions_report.doc_created_at') }}</th>
            <th>{{ $t('supplier_transactions_report.currency') }}</th>
            <th>{{ $t('supplier_transactions_report.amount') }}</th>
            <th>{{ $t('supplier_transactions_report.exchange_rate') }}</th>
            <th>{{ $t('supplier_transactions_report.doc_type') }}</th>
            <th>{{ $t('supplier_transactions_report.doc_title') }}</th>
            <th>{{ $t('supplier_transactions_report.balance_before') }}</th>
            <th>{{ $t('supplier_transactions_report.balance_after') }}</th>
            <th>{{ $t('supplier_transactions_report.wanted_amount') }}</th>
            <th>{{ $t('supplier_transactions_report.paid_amount') }}</th>
          </tr>
          </thead>
          <tbody>
          <template v-if="dataList">
            <tr v-for="(row, index) in dataList" :key="index">
              <td>{{ row.id }}</td>
              <td>{{ row.doc_created_by ? row.doc_created_by : '' }}</td>
              <td>{{ row.doc_created_at ? row.doc_created_at : '' }}</td>
              <td>{{ row.currency_name }}</td>
              <td>{{ row.amount }}</td>
              <td>{{ row.exchange_rate }}</td>
              <td>{{ row.doc_type }}</td>
              <td>{{ row.doc_title }}</td>
              <td>{{ row.balance_before }}</td>
              <td>{{ row.balance_after }}</td>
              <td>{{ row.wanted_amount }}</td>
              <td>{{ row.paid_amount }}</td>
            </tr>
          </template>


          </tbody>
        </table>
      </div>
    </div>
    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'SupplierTransactionsReport'"></report-main-header>
      </div>
    </div>

    <div class="selector-export" id="selectorExport"></div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";

export default {
  name: "index-supplier-transactions-report",
  components: {ReportMainHeader},
  data() {
    return {
      mainRoute: '/reports/supplier',
      mainRouteDependency: 'base/dependency',

      currencies: [],

      // currency_name
      // amount
      // exchange_rate
      // doc_type
      // doc_title
      // balance_before
      // balance_after
      // wanted_amount
      // paid_amount


      data_report_details: [],
      dataList: [],

      filters: {
        supplier_id: null,
        currency_id: null,
        from_date: null,
        to_date: null,
      },
      supplier: null,
      suppliers: [],

      page: 0,
      current_page: 0,
      last_page: 0,
    }
  },
  watch: {
    supplier: function (val) {
      if (val) {
        this.filters.supplier_id = val.id;
      } else {
        this.filters.supplier_id = null;
      }
    },
  },
  beforeMount() {
    this.getCurrencies();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.supplier_transactions_report")}]);
  },
  methods: {
    ...cssStypeForPrintReport,
    getRandom() {
      return Math.floor(Math.random() * 10000);
    },
    doFilter() {
      this.page = 1;
      this.getReportDetails();

    },
    resetFilter() {
      this.filters.currency_id = null;
      this.filters.supplier_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.supplier = null

    },

    async getSuppliers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },

    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },

    loadMore() {
      this.page = this.page + 1;

      this.getReportDetails();
    },
    /*****
     * details
     */

    getReportDetails() {
      ApiService.get(this.mainRoute, {params: {...this.filters, page: (this.page ? this.page : 1)}}).then((response) => {
        this.data_report_details = response.data.data.data;
        this.current_page = response.data.data.current_page;
        this.last_page = response.data.data.last_page;
      });
    },

    /**
     * actions export
     * @param tableId
     * @param _export
     */
    getDataForExport(tableId, _export) {
      ApiService.get(this.mainRoute, {params: {...this.filters, export: 1}}).then((response) => {
        this.dataList = response.data.data;
        // if (_export == 'pdf' || _export == 'print')
        //   this.printData(tableId);
        // if (_export == 'excel')
          this.convertTableToExcelReport(tableId);

      });
    },

    printData(tableId) {
      this.setReportLog('pdf', 'supplier transactions report');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },
    printPDFData(tableId, type) {
      this.setReportLog('pdf', 'supplier transactions report');
      // let newWin = window.open("");
      // let divTitleToPrint = document.getElementById('summaryTableDetailsTitle');
      // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML);
      //
      // setTimeout(() => {
      //     let divToPrint = document.getElementById(tableId);
      //     newWin.document.writeln(divToPrint.outerHTML);
      //
      //     newWin.print();
      //     newWin.close();
      // }, 100)
      exportPDFInnerAction(tableId, type, this.$t('MENU.supplier_transactions_report'), this.$t('MENU.supplier_transactions_report'));

    },

    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'supplier transactions report');
      setTimeout(() => {
        TableToExcel.convert(document.getElementById(tableId), {
          name: this.$t('MENU.supplier_transactions_report') + '.xlsx',
          sheet: {
            name: 'Sheet 1'
          }
        });
      }, 100)
    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
};
</script>
<style scoped>
.export-container {
  overflow: scroll;
  width: 1px;
  height: 1px;
}

.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.selector-export {
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}
</style>